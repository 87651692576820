const EnvVars = {
  REACT_APP_PRODUCT: process.env.REACT_APP_PRODUCT,
  REACT_APP_ATHENA_PATIENT_URL: process.env.REACT_APP_ATHENA_PATIENT_URL,
  REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  REACT_APP_STACK_DEPLOYMENT_ENV: process.env.REACT_APP_STACK_DEPLOYMENT_ENV,
  REACT_APP_SAVE_NAVIGATION_STATE: process.env.REACT_APP_SAVE_NAVIGATION_STATE,
  REACT_APP_MIXPANEL_TOKEN: process.env.REACT_APP_MIXPANEL_TOKEN,
  REACT_APP_WEB_PUSH_CERTIFICATE: process.env.REACT_APP_WEB_PUSH_CERTIFICATE,
  REACT_APP_BUILD_DATETIME: process.env.REACT_APP_BUILD_DATETIME,

  // RemoteIQ only EnvVars
  REACT_APP_ATHENA_ENCOUNTER_URL: process.env.REACT_APP_ATHENA_ENCOUNTER_URL
};

export default EnvVars;
